<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="content mt-80-mb-140" v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCollege } from '../../../server/college'
import { ref, onMounted } from 'vue'
const content = ref('')

onMounted(async () => {
  const r = await getCollege()
  content.value = r.content
})
</script>
<style lang="stylus" scoped></style>
